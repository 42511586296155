<template>
  <div>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">基本信息</span>
      </el-col>
    </el-row>
    <el-form ref="form" :model="form" label-width="120px" style="display: flex" disabled>

      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="申请单号" :class="$i18n.locale">
            <el-input v-model="form.applyId" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="关联单号" :class="$i18n.locale">
            <el-input v-model="form.orderId" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="申请类型" :class="$i18n.locale">
            <el-select v-model="form.applyType" clearable :placeholder="$t('page.selectPlaceholder')">
              <el-option label="运营申请" value="1" />
              <el-option label="计划释放" value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="申请人" :class="$i18n.locale">
            <el-input v-model="form.createByName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="申请时间" :class="$i18n.locale">
            <el-input v-model="form.createTime" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="申请总数" :class="$i18n.locale">
            <el-input v-model="form.applyPairs" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="审批人" :class="$i18n.locale">
            <el-input v-model="form.approveByName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="审批时间" :class="$i18n.locale">
            <el-input v-model="form.approveTime" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="释放总数" :class="$i18n.locale">
            <el-input v-model="form.releasePairs" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="仓库" :class="$i18n.locale">
            <el-input v-model="form.warehouseName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="平台" :class="$i18n.locale">
            <el-input v-model="form.platformCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="站点" :class="$i18n.locale">
            <el-input v-model="form.siteCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态" :class="$i18n.locale">
            <el-select v-model="form.applyStatus">
              <el-option label="待审核" :value="'0'" />
              <el-option label="审核通过" :value="1" />
              <el-option label="驳回" :value="2" />
            </el-select>
          </el-form-item>
        </el-col></el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">明细</span>
      </el-col>
    </el-row>
    <el-table
      ref="tableDatas"
      :data="tableDatas"
      :loading="tableDatasLoading"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="400px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center" />
      <el-table-column prop="style" label="Style" min-width="150" />
      <el-table-column prop="color" label="Color" min-width="150" />
      <el-table-column prop="size" label="Size" min-width="150" />
      <el-table-column prop="applyPairs" min-width="150px" label="申请数量" />
      <el-table-column prop="releasePairs" min-width="150px" label="释放数量" />
    </el-table>
    <go-back margintop />

  </div>
</template>

<script>
import GoBack from '@/components/GoBack'
import { findSafeStore, findSafeStoreDetail } from '@/api/safestock'

export default {
  components: {
    GoBack
  },
  data() {
    return {
      tableDatasLoading: false,
      form: {},
      tableDatas: []
    }
  },
  created() {

  },
  mounted() {
    const { applyId, historyId } = this.$route.query
    this._queryBasicInfo(applyId)
    this._findSafeStoreDetail(applyId, historyId, 2)
  },
  methods: {
    async _queryBasicInfo(id) {
      const { code, datas } = await findSafeStore(id)
      code === 0 ? this.form = datas : ''
    },
    async _findSafeStoreDetail(applyId, historyId, type) {
      try {
        this.tableDatasLoading = true
        const { code, datas } = await findSafeStoreDetail(applyId, historyId, type)
        if (code === 0) {
          this.tableDatas = datas
          this.tableDatasLoading = false
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.tableDatasLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
